import React, { useState } from 'react';
import './WhatsAppPopup.css';
import wht from './WhatsApp_icon.png.webp'

const WhatsAppPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="whatsapp-popup-container">
      <div className={`whatsapp-popup ${isOpen ? 'open' : ''}`}>
        <a
          href="https://wa.me/9292252592"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-link"
        >
          Chat with us on WhatsApp
        </a>
      </div>
      <button className="whatsapp-button" onClick={togglePopup}>
        <img src={wht} alt="WhatsApp" />
      </button>
    </div>
  );
};

export default WhatsAppPopup;
