import React, { useState } from 'react';
import img from '../images/webdevv.jpg';
import img2 from '../images/DM_blog_post_image_03_guetzli.jpg';
import img4 from '../images/images.jpg';
import img3 from '../images/cccam.jpg';
import softwareImg from '../images/Software-Development-Business1--1-.jpg';
import analyticsImg from '../images/data analytics challenges.webp';
import logoImg from '../images/logoser.png';
import salesforceImg from '../images/sales-img.png';
import "./services.css";


const Modal = ({ show, onClose, content }) => {
    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-8 max-w-lg w-full">
                <button onClick={onClose} className="float-right text-black font-bold">&times;</button>
                <div className="mt-4">
                    {content}
                </div>
            </div>
        </div>
    );
}

const Services = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const handleLearnMore = (content) => {
        setModalContent(content);
        setShowModal(true);
    };

    return (
        <div id="services" className="bg-gray-100 py-12 bachimg margin">
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-white uppercase font-bold">services</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-white'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-white">
                        We are deeply committed to the growth and success of our clients.
                    </h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="Software Development" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={softwareImg} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Software Development</h2>
                                <div className="flex text-md font-medium">
                                   
                                        Discover how Vedant Solutions crafts robust software solutions to streamline operations, enhance productivity, and drive business innovation.
                                   
                                </div>
                                <button onClick={() => handleLearnMore(
                                    <div>
                                        <h2 className="text-2xl font-bold">Software Development</h2>
                                        <p className="mt-4 text-gray-700">At Vedant Solutions, we specialize in building scalable and secure software applications tailored to your business requirements. Our expertise includes:</p>
                                        <ul className="list-disc pl-5 mt-2 text-gray-700">
                                            <li>Custom Software Development</li>
                                            <li>Enterprise Application Integration</li>
                                            <li>Cloud-Based Solutions</li>
                                            <li>Mobile App Development</li>
                                            <li>Quality Assurance & Testing</li>
                                        </ul>
                                        <p className="mt-4 text-gray-700">Partner with us to transform your ideas into efficient software solutions that drive growth and efficiency.</p>
                                    </div>
                                )} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">Learn More</button>
                            </div>
                        </div>
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Web Development</h2>
                                <p className="text-md font-medium">
                                    Discover how Vedant Solutions creates user-friendly websites with advanced technology to optimize functionality and enhance customer engagement, driving business growth and success.
                                </p>
                                <button onClick={() => handleLearnMore(
                                    <div>
                                        <h2 className="text-2xl font-bold">Web Development</h2>
                                        <p className="mt-4 text-gray-700">At Vedant Solutions, we specialize in creating stunning, responsive, and user-friendly websites that not only look great but also perform excellently. Our team uses the latest technologies to build websites that are secure, scalable, and tailored to meet your business needs. We focus on:
                                        </p>
                                        <ul className="list-disc pl-5 mt-2 text-gray-700">
                                            <li>Custom Web Design</li>
                                            <li>eCommerce Solutions</li>
                                            <li>Content Management Systems (CMS)</li>
                                            <li>Search Engine Optimization (SEO)</li>
                                            <li>Website Maintenance & Support</li>
                                        </ul>
                                        <p className="mt-4 text-gray-700">Partner with us to create a digital presence that stands out in today’s competitive market. Our goal is to help you attract, engage, and convert more customers through an exceptional online experience.</p>
                                    </div>
                                )} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">Learn More</button>
                            </div>
                        </div>
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Digital Marketing</h2>
                                <p className="text-md font-medium">
                                    Elevate your online presence with Vedant Solutions' tailored strategies, maximizing engagement, conversions, and ROI through targeted digital campaigns and comprehensive analytics.
                                </p>
                                <button onClick={() => handleLearnMore(
                                    <div>
                                        <h2 className="text-2xl font-bold">Digital Marketing</h2>
                                        <p className="mt-4 text-gray-700">Our digital marketing services are designed to help you achieve your business goals. We offer a comprehensive range of services, including:
                                        </p>
                                        <ul className="list-disc pl-5 mt-2 text-gray-700">
                                            <li>Search Engine Optimization (SEO)</li>
                                            <li>Social Media Marketing</li>
                                            <li>Pay-Per-Click (PPC) Advertising</li>
                                            <li>Content Marketing</li>
                                            <li>Email Marketing</li>
                                            <li>Analytics and Reporting</li>
                                        </ul>
                                        <p className="mt-4 text-gray-700">Our team of experts will work with you to develop a customized strategy that targets your audience and drives meaningful results. Let us help you increase your online visibility, engage your customers, and grow your business.</p>
                                    </div>
                                )} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">Learn More</button>
                            </div>
                        </div>
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="Logo Creation" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={logoImg} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Logo Creation</h2>
                                <p className="text-md font-medium">
                                    Discover how Vedant Solutions designs distinctive logos that resonate with your brand identity and captivate your audience.
                                </p>
                                <button onClick={() => handleLearnMore(
                                    <div>
                                        <h2 className="text-2xl font-bold">Logo Creation</h2>
                                        <p className="mt-4 text-gray-700">At Vedant Solutions, we specialize in creating impactful logos that visually represent your brand values and messaging. Our approach focuses on:</p>
                                        <ul className="list-disc pl-5 mt-2 text-gray-700">
                                            <li>Custom Logo Design</li>
                                            <li>Brand Identity Development</li>
                                            <li>Logo Refresh and Updates</li>
                                            <li>Graphic Design Services</li>
                                            <li>Brand Style Guides</li>
                                        </ul>
                                        <p className="mt-4 text-gray-700">Partner with us to create a logo that leaves a lasting impression and strengthens your brand presence.</p>
                                    </div>
                                )} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">Learn More</button>
                            </div>
                        </div>


{/* 
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="Data Analytics" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={analyticsImg} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Data Analytics</h2>
                                <p className="text-md font-medium">
                                    Explore how Vedant Solutions leverages data insights to drive strategic decisions and business performance improvements.
                                </p>
                                <button onClick={() => handleLearnMore(
                                    <div>
                                        <h2 className="text-2xl font-bold">Data Analytics</h2>
                                        <p className="mt-4 text-gray-700">At Vedant Solutions, we empower businesses with actionable insights through advanced data analytics and visualization. Our services include:</p>
                                        <ul className="list-disc pl-5 mt-2 text-gray-700">
                                            <li>Data Visualization</li>
                                            <li>Predictive Analytics</li>
                                            <li>Business Intelligence</li>
                                            <li>Data Warehousing</li>
                                            <li>Data Governance</li>
                                        </ul>
                                        <p className="mt-4 text-gray-700">Partner with us to harness the power of your data and gain a competitive edge in your industry.</p>
                                    </div>
                                )} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">Learn More</button>
                            </div>
                        </div> */}

                        {/* <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="Salesforce Services" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={salesforceImg} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Salesforce Services</h2>
                                <p className="text-md font-medium">
                                    Explore how Vedant Solutions optimizes Salesforce platforms to streamline processes, enhance customer relationships, and drive business growth.
                                </p>
                                <button onClick={() => handleLearnMore(
                                    <div>
                                        <h2 className="text-2xl font-bold">Salesforce Services</h2>
                                        <p className="mt-4 text-gray-700">At Vedant Solutions, we offer comprehensive Salesforce consulting and implementation services tailored to your business needs. Our expertise includes:</p>
                                        <ul className="list-disc pl-5 mt-2 text-gray-700">
                                            <li>Sales Cloud</li>
                                            <li>Service Cloud</li>
                                            <li>Marketing Automation</li>
                                            <li>Community Cloud</li>
                                            <li>Integration Solutions</li>
                                        </ul>
                                        <p className="mt-4 text-gray-700">Partner with us to leverage Salesforce for driving operational efficiency and achieving business success.</p>
                                    </div>
                                )} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">Learn More</button>
                            </div>
                        </div> */}




                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold my-4 text-2xl text-center">CC Camera Services</h2>
                                <p className="text-md font-medium">
                                    Ensure peace of mind with Vedant Solutions' advanced surveillance solutions, offering robust security systems tailored to protect businesses and homes with reliability and efficiency.
                                </p>
                                <button onClick={() => handleLearnMore(
                                    <div>
                                        <h2 className="text-2xl font-bold">CC Camera Services</h2>
                                        <p className="mt-4 text-gray-700">Vedant Solutions provides comprehensive CC Camera services to ensure the safety and security of your premises. Our offerings include:
                                        </p>
                                        <ul className="list-disc pl-5 mt-2 text-gray-700">
                                            <li>Installation of High-Resolution Cameras</li>
                                            <li>24/7 Monitoring Solutions</li>
                                            <li>Remote Access and Control</li>
                                            <li>Maintenance and Support Services</li>
                                            <li>Customized Security Solutions</li>
                                        </ul>
                                        <p className="mt-4 text-gray-700">With our state-of-the-art technology and experienced team, we provide reliable security solutions that give you peace of mind. Protect your business and home with our trusted surveillance systems.</p>
                                    </div>
                                )} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">Learn More</button>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold my-4 text-2xl text-center">BioMetric Access</h2>
                                <p className="text-md font-medium">
                                    Experience secure and streamlined access control solutions by Vedant Solutions, leveraging cutting-edge biometric technology to safeguard assets and enhance operational efficiency.
                                </p>
                                <button onClick={() => handleLearnMore(
                                    <div>
                                        <h2 className="text-2xl font-bold">BioMetric Access</h2>
                                        <p className="mt-4 text-gray-700">Our BioMetric Access solutions are designed to provide secure and efficient access control for your business. We offer:
                                        </p>
                                        <ul className="list-disc pl-5 mt-2 text-gray-700">
                                            <li>Fingerprint and Facial Recognition Systems</li>
                                            <li>Time and Attendance Management</li>
                                            <li>Access Control Integration</li>
                                            <li>Real-Time Monitoring and Reporting</li>
                                            <li>Customizable Solutions</li>
                                        </ul>
                                        <p className="mt-4 text-gray-700">Enhance your security and streamline your operations with our advanced biometric solutions. Our systems are designed to be highly accurate, reliable, and easy to use.</p>
                                    </div>
                                )} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">Learn More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-white mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
                            </div>
                            <h3 className="text-3xl text-white font-bold">We <span className='font-black'>Build</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-400 font-semibold'>
                                    Vedant Solutions is committed to deliver the best in creative Web Design, Web Cams, Promotions, Digital Marketing Consulting, and Bio-metric Access Services.
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-white mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path></svg>
                            </div>
                            <h3 className="text-3xl text-white font-bold">We <span className='font-black'>Collaborate</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-400 font-semibold'>
                                    Vedant Solutions can collaborate with your existing tech team to scale existing software applications or design customized software applications that suits your everyday need and simplifies various processes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={showModal} onClose={() => setShowModal(false)} content={modalContent} />
        </div>
    )
}

export default Services;
