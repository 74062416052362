import React from 'react';
import { useInView } from 'react-intersection-observer';
import img from './Navbar/WhatsApp Image 2024-08-23 at 14.55.44_5ede8617.jpg';
import { Link } from 'react-router-dom';
import { ReactTyped } from 'react-typed';


const Intro = () => {
    const { ref: imgRef, inView: imgInView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    const { ref: headingRef, inView: headingInView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    const { ref: textRef, inView: textInView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    const { ref: offerRef, inView: offerInView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    const { ref: buttonRef, inView: buttonInView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <div className='bachimg margin'>
            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about'>
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left">
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                        <img
                            ref={imgRef}
                            alt="card img"
                            className={`webdevimg transform transition duration-500 ${imgInView ? 'fade-in' : 'opacity-0'}`}
                            src={img}
                        />
                        <div className="pt-4">
                            <h1 className="mb-5 md:text-3xl text-xl font-bold text-white">
                                <ReactTyped
                                    strings={['We Find The Solutions']}
                                    typeSpeed={100}
                                    backSpeed={25}
                                    loop={true}
                                />
                            </h1>
                        </div>
                    </div>
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8">
                        <h3
                            ref={headingRef}
                            className={`text-3xl text-white font-bold ${headingInView ? 'fade-in-up' : 'opacity-0'}`}
                        >
                            Welcome to Vedant Solutions
                        </h3>
                        <div>

                            <p
                                ref={textRef}
                                className={`my-3 text-xl text-gray-400 font-semibold ${textInView ? 'fade-in-up' : 'opacity-0'}`}
                            >
                                Our mission is to deliver innovative, high-quality software solutions that drive growth and efficiency.
                            </p>
                        </div>

                        <div
                            ref={offerRef}
                            className={`${offerInView ? 'fade-in-up' : 'opacity-0'}`}
                        >
                            <h3 className="text-3xl text-white font-bold">What We Offer</h3>
                            <p className='my-3 text-xl text-gray-400 font-semibold'>
                                <span>Custom Software Development:</span> Tailored solutions that fit your business like a glove.
                            </p>
                            <p className='my-3 text-xl text-gray-400 font-semibold'>
                                <span>Digital Marketing:</span> Strategies that amplify your online presence and drive engagement.
                            </p>
                            <p className='my-3 text-xl text-gray-400 font-semibold'>
                                <span>CC Camera Services:</span> Advanced surveillance solutions to keep your premises secure.
                            </p>
                            <p className='my-3 text-xl text-gray-400 font-semibold'>
                                <span>Biometric Access:</span> Cutting-edge access control systems for enhanced security.
                            </p>
                        </div>
                        <Link
                            ref={buttonRef}
                            to="/contact"
                            className={`button-animation text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group transform transition duration-500 ${buttonInView ? 'fade-in-up' : 'opacity-0'}`}
                        >
                            Contact us
                            <svg className="w-4 h-4 ml-1 transition-transform" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Intro;
