import React, { useEffect, useState } from 'react';
import img from './proffisional_pic.jpg'
const testimonialContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#f8f9fa',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    margin: '1rem',
    minWidth: '300px',
    textAlign: 'center'
};

const testimonialImageStyle = {
    height: '100px',
    width: '100px',
    borderRadius: '50%',
    marginBottom: '1rem'
};

const carouselContainerStyle = {
    display: 'flex',
    overflow: 'hidden',
    width: '100%'
};

const carouselTrackStyle = {
    display: 'flex',
    animation: 'scroll 30s linear infinite'
};

const Testimonials = () => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        // JSON data included directly in the component
        const data = [
            {
                id: 1,
                name: "Dr.B.Rama Krishna Founder of Rks Foundation",
                image: "https://rksmotherteresafoundation.com/images/temp/ramakrishna.jpg",
                testimonial: "Thank you Vedant Solutions For Your Payment Gateway integration we Appreciate Your Work And Dedication",
                link: "https://rksmotherteresafoundation.com"
            },
            {
                id: 2,
                name: "Travel Again",
                image: "https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=200,fit=crop,q=95/YBgpKr8rOMc095oZ/travelagain-removebg-1-m5Kv0wBE4Zt2LOJb.png",
                testimonial: "I am extremely grateful to Vedant Solutions for their continuous help and encouragement. They are truly amazing.",
                link: "https://explorewithtravelagain.com/"
            },
            {
                id: 3,
                name: "Elevatesphere.tech",
                image: 'https://elevatesphere.tech/static/media/Brand_Name.51b9f9db033be355bd53.png',
                testimonial: "The work done by Vedant Solutions. They have made a significant impact on my community.",
                link: "https://elevatesphere.tech"
            }
        ];
        setTestimonials(data);
    }, []);

    return (
        <div id="testimonials" className="mt-8 bg-green-100 p-8 bachimg margin ">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-white uppercase font-bold">Testimonials</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-white'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-white">What our clients say.</h2>
                </div>

                <div style={carouselContainerStyle}>
                    <div style={carouselTrackStyle}>
                        {testimonials.map(testimonial => (
                            <div key={testimonial.id} style={testimonialContainerStyle}>
                                <img src={testimonial.image} alt={testimonial.name} style={testimonialImageStyle} />
                                <h3 className="text-xl font-bold">{testimonial.name}</h3>
                                <p className="text-gray-700 mt-4">{testimonial.testimonial}</p>
                                <a className="text-gray-700 mt-4" href={testimonial.link} target="_blank" rel="noopener noreferrer">{testimonial.link}</a>
                            </div>
                        ))}
                        {/* Duplicate the testimonials to create the infinite loop effect */}
                        {testimonials.map(testimonial => (
                            <div key={`${testimonial.id}-duplicate`} style={testimonialContainerStyle}>
                                <img src={testimonial.image} alt={testimonial.name} style={testimonialImageStyle} />
                                <h3 className="text-xl font-bold">{testimonial.name}</h3>
                                <p className="text-gray-700 mt-4">{testimonial.testimonial}</p>
                                <a className="text-gray-700 mt-4" href={testimonial.link} target="_blank" rel="noopener noreferrer">{testimonial.link}</a>
                                
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <style jsx>{`
                @keyframes scroll {
                    0% { transform: translateX(0); }
                    100% { transform: translateX(-100%); }
                }
            `}</style>
        </div>
    );
}

export default Testimonials;
